// example: Jun 2018
export function shortMonthString(date: Date) {
    return date.toLocaleString(getBrowserLocale(), { month: "short" }) + " " + date.getFullYear();
}

// example: Jun 1, 2018
export function shortDateString(date: Date) {
    return (
        date.toLocaleString(getBrowserLocale(), { month: "short" }) + " " + date.getDate() + ", " + date.getFullYear()
    );
}

export function getBrowserLocale() {
    const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

    return userLocale || "en-US";
}

export function format(str: string, ...args: string[]) {
    for (let i = 0; i < args.length; i++) {
        const reg = new RegExp("\\{" + i + "\\}", "gm");
        str = str.replace(reg, args[i]);
    }
    return str;
}

export function formatNumber(no?: number): string {
    return typeof no === "number" && !isNaN(no) ? no.toLocaleString() : "";
}
